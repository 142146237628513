<template>
  <div>
    <p class="trading__item">
      <span> {{ i18n.t('authPage.server') }}: </span>
      <br />
      <span>
        <b>{{ tradingInfo.server }}</b>
      </span>
    </p>
    <p class="trading__item">
      <span> {{ i18n.t('menuItems.login') }}: </span>
      <br />
      <span>
        <b @click="copyInfo({ data: tradingInfo.login, visible: true, type: 'login' })">{{ tradingInfo.login }}</b>
      </span>
    </p>
    <p class="trading__item trading__password">
      <span> {{ i18n.t('authPage.traders_password') }}: </span>
      <br />
      <span>
        <b
          @click="
            copyInfo({ data: tradingInfo.master_password, visible: passwordVisible.master, type: 'traders_password' })
          "
          >{{ passwordVisible.master ? tradingInfo.master_password : '• • • • • • • •' }}</b
        >
        <sdFeatherIcons
          :type="passwordVisible.master ? 'eye-off' : 'eye'"
          @click="passwordVisible.master = !passwordVisible.master"
          style="opacity: 0.7"
          size="13"
        />
      </span>
    </p>
    <p class="trading__item trading__password">
      <span> {{ i18n.t('authPage.investor_password') }}: </span>

      <br />
      <span>
        <b
          @click="
            copyInfo({
              data: tradingInfo.investor_password,
              visible: passwordVisible.invest,
              type: 'investor_password',
            })
          "
          >{{ passwordVisible.invest ? tradingInfo.investor_password : '• • • • • • • •' }}</b
        >
        <sdFeatherIcons
          :type="passwordVisible.invest ? 'eye-off' : 'eye'"
          @click="passwordVisible.invest = !passwordVisible.invest"
          style="opacity: 0.7"
          size="13"
        />
      </span>
    </p>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  props: { tradingInfo: Object },
  setup() {
    const i18n = useI18n();
    const passwordVisible = ref({ invest: false, master: false });

    const copyInfo = ({ data, visible, type }) => {
      if (visible) {
        window.navigator.clipboard.writeText(data);
        message.success(i18n.t(`authPage.${type}`) + i18n.t('messages.copied'));
      } else {
        message.error(i18n.t(`authPage.${type}`) + i18n.t('messages.notCopied'));
      }
    };
    return {
      passwordVisible,
      copyInfo,
      i18n,
    };
  },
};
</script>
